<template>
  <v-dialog v-model="isDuplicateModalOpen" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h3 font-weight-medium">
        {{ $t("duplicate_order_title") }}</v-card-title
      >
      <v-card-text class="text-h5">
        Are you sure you want to duplicate this order ? We will create a new
        draft order with all its information
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="close()" color="indigo" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn class="primary" @click="confirm()" text> Confirm </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isDuplicateModalOpen: Boolean,
    toggleDuplicateModal: Function,
    orderItem: Object,
  },
  mounted: function () {
    this.currentOrderItem = { ...this.orderItem };
  },
  data() {
    return {
      newId: null,
    };
  },
  methods: {
    close() {
      this.toggleDuplicateModal();
    },
    async confirm() {
      console.log(this.currentOrderItem);
      await this.$store
        .dispatch("supplyOrders/duplicate", this.currentOrderItem, {
          supply_order_id: this.currentOrderItem.id,
        })
        .then(async (data) => {
          this.newId = data.supply_order.id;
        });
      this.$router.push({
        name: "supplyOrders.edit",
        params: { id: this.newId },
      });
    },
  },
};
</script>